
import Vue from "vue";
import SearchResult from "@/components/SearchMentor/SearchResult.vue";

export default Vue.extend({
  name: "SearchResults",
  components: {
    SearchResult
  },
  props: { users: { type: Array, default: null, required: false } }
});
