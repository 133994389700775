
import Vue from "vue";
import Search from "@/components/SearchMentor/Search.vue";
import SearchResults from "@/components/SearchMentor/SearchResults.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SearchMentorResultsPage",
  components: {
    Search,
    SearchResults
  },
  data: function() {
    return { searchVal: null };
  },
  computed: {
    ...mapGetters({
      users: "search/getUsers"
    })
  },
  methods: {
    onSearch(q: string) {
      this.$store.dispatch("search/SEARCH_USERS", { q });
      //@ts-ignore
      this.searchVal = q;
    },
    onLoadMore() {
      console.log("Load More button was clicked!");
    }
  }
});
